import { FC, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Footer } from "./components/footer/Footer";
import { Navbar } from "./components/navbar/Navbar";
import { CompleteProfile } from "./views/completeProfile/CompleteProfile";
import { DriverReservation } from "./views/driverReservation/show/component";
import { DriverReservations } from "./views/driverReservation/index/component";
import { Home } from "./views/home/Home";
import { Profile } from "./views/profile/Profile";
import { Results } from "./views/results/Results";
import { SignUp } from "./views/signup/Signup";
import { CreateReservation } from "./views/driverReservation/create/CreateReservation";
import { Verification } from "./views/verification/Verification";
import { Car } from "./views/car/show/ShowCar";
import { HostHome } from "./views/hostHome/HostHome";
import { CarIndex } from "./views/car/index/component";
import { AddCar } from "./views/car/add/AddCar";
import { EditCar } from "./views/car/edit/EditCar";
import { HostReservations } from "./views/hostReservation/index/component";
import { HostReservation } from "./views/hostReservation/show/component";
import { CommercialSignup } from "./views/commercialSignup/CommercialSignup";
import { CommercialHome } from "./views/commercialHome/CommercialHome";
import CheckoutSuccessful from "./views/driverReservation/success/component";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ProtectedRoute } from "./lib/components/protectedRoute/component";
import useAnalytics from "./hooks/useAnalytics";
import InProgress from "./components/inProgressView/InProgressView.component";
import ForgotPassword from "./views/resetPassword/forgotPassword/ForgotPassword";
import SendEmailConfirmation from "./views/resetPassword/sendEmailConfirmation/SendEmailConfirmation";
import ResetPasswordForm from "./views/resetPassword/resetPasswordForm/ResetPasswordForm";
import { LoginContext, useRoot } from "./hooks/root";
import { PageNotFound } from "./views/pageNotFound/PageNotFound";
import { KycSuccess } from "./views/pagesKYC/KycSuccess/KycSuccess";
import { LicenseInfo } from "@mui/x-license-pro";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { Chat } from "./views/chat/Chat";
import { DomainContext } from "./providers/domainProvider";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI || "");

export const App: FC = () => {
  const { settings } = useContext(DomainContext);
  const [stripeObject, setStripeObject] = useState<
    Stripe | PromiseLike<Stripe | null> | null
  >(null);
  const {
    isLogged,
    value,
    user,
    t,
    isAuthorized,
    isComplete,
    theme,
    setTheme,
    sendBirdData,
  } = useRoot();

  useEffect(() => {
    if (settings) {
      setStripeObject(
        loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "", {
          stripeAccount: settings.payment.stripeAccount,
        })
      );
    }
  }, [settings]);
  // Enable analytics
  useAnalytics(user);

  return (
    <Elements stripe={stripeObject}>
      <LoginContext.Provider value={value}>
        <Navbar />
      </LoginContext.Provider>
      <Routes>
        {settings.routes.homePage && (
          <Route path={t("root")} element={<Home />} />
        )}
        {settings.routes.homeHost && (
          <Route path={t("host")} element={<HostHome />} />
        )}
        {settings.routes.homeHostCommercial && (
          <Route path={t("commercial_host")} element={<CommercialHome />} />
        )}
        <Route
          path={t("drivers") + "/" + t("chat")}
          element={
            sendBirdData && (
              <SendbirdProvider
                theme={
                  theme.toLocaleLowerCase() as "light" | "dark" | undefined
                }
                breakpoint={"900px"}
                appId={process.env.REACT_APP_APP_ID || ""}
                userId={sendBirdData.id}
              >
                <Chat />
              </SendbirdProvider>
            )
          }
        />
        <Route
          path={t("hosts") + "/" + t("chat")}
          element={
            sendBirdData && (
              <SendbirdProvider
                theme={
                  theme.toLocaleLowerCase() as "light" | "dark" | undefined
                }
                breakpoint={"900px"}
                appId={process.env.REACT_APP_APP_ID || ""}
                userId={sendBirdData.id + "-Host"}
              >
                <Chat />
              </SendbirdProvider>
            )
          }
        />
        <Route
          path={t("verify")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <Verification />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("drivers") + "/" + t("trips")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <DriverReservations />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("profile")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("my_cars")}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={isLogged && isAuthorized}
            >
              <CarIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("commercial_signup")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <CommercialSignup />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("recover_password")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <ForgotPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("sent_recover_password")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <SendEmailConfirmation />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("reset_password")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed>
              <ResetPasswordForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("signup")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={!isLogged}>
              <LoginContext.Provider value={value}>
                <SignUp />
              </LoginContext.Provider>
            </ProtectedRoute>
          }
        />
        <Route
          path={t("complete_profile")}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={isLogged && !isComplete}
            >
              <LoginContext.Provider value={value}>
                <CompleteProfile />
              </LoginContext.Provider>
            </ProtectedRoute>
          }
        />
        <Route
          path={t("upload_car")}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <AddCar />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("edit") + "/:carId"}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={isLogged && isAuthorized}
            >
              <EditCar />
            </ProtectedRoute>
          }
        />

        <Route
          path={t("hosts") + "/" + t("trips")}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={isLogged && isAuthorized}
            >
              <HostReservations />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("hosts") + "/" + t("trips") + "/:HostReservationId"}
          element={
            <ProtectedRoute
              redirectPath={t("not_found")}
              isAllowed={isLogged && isAuthorized}
            >
              <HostReservation />
            </ProtectedRoute>
          }
        />
        <Route
          path={t("drivers") + "/" + t("trips") + "/:reservationId"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <DriverReservation />
            </ProtectedRoute>
          }
        />
        <Route path={t("details") + "/:carId"} element={<Car />} />
        <Route path={t("results")} element={<Results />} />
        <Route
          path={`${t("drivers")}/${t("favorites")}`}
          element={<InProgress />}
        />
        <Route
          path={`${t("hosts")}/${t("progress")}`}
          element={<InProgress />}
        />

        {/* Analytics */}
        <Route
          path={"checkout/checkout_successful"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <CheckoutSuccessful />
            </ProtectedRoute>
          }
        />
        <Route
          // FAIL ROUTE
          path={"checkout/checkout_failed"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <CheckoutSuccessful />
            </ProtectedRoute>
          }
        />
        {/* Checkout */}
        <Route
          path={t("checkout") + "/:tripId"}
          element={
            <ProtectedRoute redirectPath={t("not_found")} isAllowed={isLogged}>
              <CreateReservation />
            </ProtectedRoute>
          }
        />
        <Route path={t("not_found")} element={<PageNotFound />} />
        <Route path={t("kyc_success")} element={<KycSuccess />} />
      </Routes>
      <Footer setTheme={setTheme} theme={theme} />
    </Elements>
  );
};
